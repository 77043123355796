import axios from 'axios'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Datepicker from 'vuejs-datepicker'
import {nbNO, en} from 'vuejs-datepicker/dist/locale'
import { auth, envelopes, oidc } from '@verified/libvf'
import zipNO from '../../assets/postnummer_no.json'

export default {
	name: "form-kyc-company",
	props: {
		sheetname: {
			type: String
		},
		role: {
			type: String
		},
		account: {
			type: String
		},
		lang: {
			type: String
		},
		userdata: {
			type: Object,
			default: function() { return {} }
		},
		envelope: {
			type: Object,
			default: function() { return {} }
		},
		config: {
			type: Object,
			default: function() { return {} }
		},
		translations: {
			type: Object
		},
		mobile: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			en: en,
			no: nbNO,
			zipJson: undefined,
			startDate: new Date('1970'+"-"+'01'+"-"+'01'),
			loading: true,
			submitted: false,
			searching: false,
			searchComplete: false,
			alreadyCompletedSearch: false,
			brreg_search: true,
			logout: "../logout.png",
			loader: "../loader64.gif",
			new_doc: "../new-document.png",
			logo: undefined,
			qp: [],
			company: {},
			loggedIn: false,
			error: false,
			failedAuth: undefined,
			redirectURL: undefined,
			loadingLists: false,
			busy: false,
			exceptions: [],
			failed: false,
			auth: {},
			step: 1,
			steps_owner: [{ title: "", button: "" }],
			steps_secretary: [{ title: "", button: "" }],
			steps_representative: [{ title: "", button: "" }],
			forward: undefined,
			invoiceSpecification: [],
			network: [],
			ranges: [],
			lawyers: [],
			discounts: [],
			risks: [],
			employees: [],
			positions: [],
			silentParties: [],
			signingMethods : {},
			signing_methods_list: [],
			countries: [],
			nationalities: [],
			greeting: "",
			owner_options : {},
			searchQuery: undefined,
			isListed: undefined,
			wasReferred: undefined,
			customerType: [],
			isCorporate: undefined,
			capitalOriginQ1: undefined,
			capitalOriginQ3: undefined,
			assignmentAgreement: [],
			credentials: [],
			recipients: [],
			invoiceType: undefined,
			ud: {
				amlSearchSuccessful: false,
				company: {
					name: "",
					org_no: "",
					address: "",
					zip: "",
					town: "",
					registration_date: "",
					employees: "",
					start_date: "",
					org_form: "",
					business_code: "",
					business: "",
					reputation: {
						risk: "",
						other: ""
					}
				},
				contacts: [{
					fname: "",
					lname: ""
				}],
				invoice: {},
				remuneration: {
					mva: {},
					discount: {}
				},
				owner_question1: {},
				owner_question2: {},
				pep_question: {},
				ceo: {},
				kyc: {},
				client: {},
				representative: {
					fname: "",
					lname: "",
					email: ""
				},
				signer: {
					fname: "",
					lname: "",
					email: "",
					signing: {}
				},
				remunerations: [
					{
						lawyer: "",
						rate: undefined,
						selectedIndex: undefined
					}
				],
				officialRoles: [],
				owners: [],
				entities: [],
				entitiesTypePerson: [],
				entitiesTypeOrg: [],
				minPEP: 1,
				pepIDs: [],
				peps: [],
				signers : [],
				kyc_list: [],
				secretaries_list: [],
				customer_selection_list: [],
				signers_list : [],
				rejectedComment: "",
				positionIndex: undefined,
				riskIndex: undefined,
				referredByIndex: undefined,
				invoiceSpecificationIndex: undefined,
				networkIndex: undefined,
				annualTurnoverIndex: undefined,
				approved: {
					representative: undefined,
					representative2: undefined
				},
				inBrreg: {
					brreg_true: true,
					brreg_false: false
				}
			},
			smallWindow: false,
			windowWidth: 0,
			formOpacity: 0,
			showInvalidFeedback: {
				"datepicker-start-date" : false,
				"datepicker-reg-date" : false,
				"datepicker-ceo" : false,
				"datepicker-owner" : false,
				"datepicker-pep": false
			},
			showInputsUnion : true,
			isIE: undefined,
			isEdge: undefined,
			approved: undefined,
			aborted: undefined,
			kycDetails: undefined
		}
		/**
		 * 
				case: {
					name: "",
					type: "",
					counterpart: "",
					counterpartLawyer: "",
					customerManager: "",
					responsible: "",
					officer: ""
				},
				secretary: {
					fname: "",
					lname: "",
					email: ""
				},
		 */
	},
	components: {
		Datepicker
	},
	computed: {
		search: {
			// getter
			get: function () {
				return this.brreg_search
			},
			// setter
			set: function (newValue) {
				this.brreg_search = newValue
			}
		}
	},
	watch: {
		'ud.recipient.greeting': function (val) {
			this.ud.recipient.greeting = val
		},
		windowWidth: function () {
			if(window.innerWidth <= 800){ 
				this.smallWindow = true;
			}
		},
		lang: function (val) {
			var v = val
			console.log(v)
			this.loadLists()
		}
	},
	methods: {
		// Return config for given key
		__config(key) {
			if (this.config[key])
				return this.config[key].value
			return null
		},
		getURL() {
			if (location.search) {
				if (location.search.substring(1, 2) == "&") {
					this.qp = location.search.substring(0, 1) + location.search.substring(2, location.search.length);
					this.qp = JSON.parse("{\"" + decodeURI(qp.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
				} else
					this.qp = JSON.parse("{\"" + decodeURI(location.search.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
			}
		},
		close() {
			location.href = this.redirectURL
		},
		validateForm(form) {
			return this.$refs[form].validate()
		},
		validateDatepicker(id) {
			var element = document.getElementById(id);
			if(element) {
				var value = element.value

				if(!value) {
					this.showInvalidFeedback[id] = true;
					return false
				} else {
					this.showInvalidFeedback[id] = false;
					return true
				}
			}
		},
		fadeToStep(step) {
			this.formOpacity = 0
			window.scrollTo(0,0)
			setTimeout(() => {
				this.step = step
				if(step === 0)
					this.hidePortalContent = false
				setTimeout(() => {
					this.contentOpacity = this.formOpacity = 1
				}, 10)
			}, 500)
		},
		async nextStep() {

			if(this.role == 'owner') {
				if(this.step == 1)
					if(this.ud.brreg) {
						if(!this.validateForm(`form${this.step+1}`)) {
							this.validateDatepicker("datepicker-start-date")
							this.validateDatepicker("datepicker-reg-date")
							return
						} else if(!this.validateDatepicker("datepicker-start-date")) 
							return
						else if(!this.validateDatepicker("datepicker-reg-date")) 
							return
						else {
							if(this.ud.company.org_no !== undefined) {
								let org = (this.ud.company.org_no.toString()).replace(/\s/g, "")
								if(!isNaN(org)) {
									this.formOpacity = 0
									window.scrollTo(0,0)
									await this.getAmlData(this.ud.company.org_no)
									setTimeout(function () { this.formOpacity = 1 }.bind(this), 500)
								}
							}
						}
					} else {
						if(!this.validateForm(`form${this.step+1}`))
							return
					}
				else if(this.step > 1 && this.step < 8)
					if(!this.validateForm(`form${this.step+1}`))
						return

				console.log("HERE")

				if(this.step < 7)
					this.fadeToStep(this.step + 1)
				else 
					return this.submit()

			}
			
			if(this.role == 'kundeutvalg'||this.role == 'kundeutvalg-2') {
				if (this.step == 1) {
					this.fadeToStep(2)
				} else if(this.step > 1 && this.step <= 8)
					if(!this.validateForm(`form${this.step}`))
						return

				if(this.step < 8)
					this.fadeToStep(this.step + 1)
				else 
					return this.submit()
			}
		},
		previousStep() {
			this.fadeToStep(this.step - 1)
		},
		showKYC() {
			this.ud.kycDetails = this.kycDetails.yes ? true : false
			this.$forceUpdate()
		},
		setKYC() {
			if(this.ud.kyc.type) {
				for(var k in this.ud.kyc_list)
					if(this.ud.kyc_list[k] === this.ud.kyc.type)
						this.ud.kyc.selectedIndex = Number(k)

				switch(this.ud.kyc.selectedIndex) {
					case 0 : { this.ud.kyc.level = "low"; break; }
					case 1 : { this.ud.kyc.level = "medium"; break; }
					case 2 : { this.ud.kyc.level = "high"; break; }
					default: this.ud.kyc.level = null
				}

				this.$forceUpdate()
			}
		},
		setClientType() {
			if(this.ud.client.selected) {
				for(var i in this.ud.client.selected)
					if(this.ud.client.selected[i]) {
						this.ud.client.type = this.__(`client_type_${i}`)
						this.$forceUpdate()
						return
					}
			}
		},
		setSecretary() {
			if(this.ud.secretary.name) {
				for(var s in this.ud.secretaries) {
					if(this.ud.secretaries[s].name === this.ud.secretary.name)  {
						this.ud.secretary.fname = this.ud.secretaries[s]["firstname"]
						this.ud.secretary.lname = this.ud.secretaries[s]["lastname"]
						this.ud.secretary.email = this.ud.secretaries[s]["email"]
						break
					}
				}
				
				this.$forceUpdate()
			}
		},
		setCustomerSelection() {
			if(this.ud.representative.name) {
				for(var c in this.ud.customerSelection) {
					if(this.ud.customerSelection[c].name === this.ud.representative.name) {
						this.ud.representative.fname = this.ud.customerSelection[c]["firstname"]
						this.ud.representative.lname = this.ud.customerSelection[c]["lastname"]
						this.ud.representative.email = this.ud.customerSelection[c]["email"]
						break
					}
				}
				
				this.$forceUpdate()
			}
		},
		setSigningMethods() {
			if(this.ud.signer.signing.label) {
				for(var method in this.signingMethods)
					if(this.signingMethods[method].label === this.ud.signer.signing.label) {
						this.ud.signer.signing.method = method
						this.ud.signer.lang = this.signingMethods[method].lang
					}
			}

			this.$forceUpdate()
		},
		setInBrreg() {
			this.ud.brreg = this.ud.inBrreg.brreg_true ? true : false

			if(!this.ud.brreg) this.ud.company.org_form = this.translations.org_form_notInBrreg[this.lang]
			this.$forceUpdate()
		},
		setInvoiceType() {
			this.ud.invoice.type = this.ud.invoiceType.email ? 'email' : 'ehf'

			if(!this.ud.brreg) this.ud.company.org_form = this.translations.org_form_notInBrreg[this.lang]
			this.$forceUpdate()
		},
		setCaseMVA() {
			this.ud.remuneration.mva.val = this.ud.caseMVA.mva_true ? true : false
			this.$forceUpdate()
		},
		setDiscount() {
			this.ud.remuneration.discount.selectedIndex = this.discounts.indexOf(this.ud.remuneration.discount.val)
			this.ud.remuneration.discount.isGiven = this.ud.remuneration.discount.selectedIndex !== (this.discounts.length-1) ? true : false
			this.$forceUpdate()
		},
		setReferred() {
			this.ud.wasReferred = this.ud.referred.referred_true ? true : false
			this.$forceUpdate()
		},
		setPurpose() {
			this.ud.purpose_other = this.ud.purpose.includes("Annet") ? true : false
			this.ud.purpose_purchase = this.ud.purpose.includes("Kjøp") ? true : false
			this.$forceUpdate()
		},
		setFormType() {
			//this.ud.formType = this.ud.form_type.form_type_customer ? 'customer' : 'case'

			if(this.ud.formType == 'customer') {
				this.ud.owner_question1 = {
					"owners_A1": false,
					"owners_A2": false,
					"owners_A3": false
				}
				this.ud.owner_question2 = {
					"owner_structure_A1": false,
					"owner_structure_A2": false
				}
				this.ud.pep_question = {
					"pep_A1": false,
					"pep_A2": false,
					"pep_A3": false
				}

			} else if(this.ud.formType == 'case') {
				this.ud.owner_question1 = {
					"owners_A1": false,
					"owners_A2": false,
					"owners_A3": false,
					"owners_A4": false
				}
				this.ud.owner_question2 = {
					"owner_structure_A1": false,
					"owner_structure_A2": false,
					"owner_structure_A3": false
				}
				this.ud.pep_question = {
					"pep_A1": false,
					"pep_A2": false,
					"pep_A3": false
				}
			}

			this.$forceUpdate()
		},
		setPosition(i) {
			this.ud.contacts[i].position_other = this.ud.contacts[i].position.includes("Annet") ? true : false
			if(this.ud.contacts[i].position) this.ud.contacts[i].selectedIndex = this.positions.indexOf(this.ud.contacts[i].position)
			this.$forceUpdate()
		},
		setRisk() {
			this.ud.company.reputation.other = this.ud.company.reputation.risk !== this.__('low') ? true : false
			if(this.ud.company.reputation.risk) this.ud.company.reputation.selectedIndex = this.risks.indexOf(this.ud.company.reputation.risk)
			this.$forceUpdate()
		},
		setApproved() {
			if(this.role == 'kundeutvalg')
				this.ud.approved.representative = this.approved.approved_true ? true : false

			if(this.role == 'kundeutvalg-2')
				this.ud.approved.representative2 = this.approved.approved_true ? true : false

			this.$forceUpdate()
		},
		showPeps() {
			if(this.ud.pep_question.pep_A1 == true && this.ud.peps.length == 0)
				this.ud.peps.push({
					fname: "",
					lname: "",
					dob: "",
					address: "",
					zip: "",
					town: "",
					nationality: "",
					country: "",
					public_function: "",
					career_history: ""
				})
			else this.ud.peps = []
		},
		showOwners() {
			this.ud.company.hasOwners = this.ud.owner_question1.owners_A1 ? true : false

			if(this.ud.company.hasOwners == true && this.ud.owners.length == 0)
				this.ud.owners.push({
						fname: "",
						lname: "",
						dob: "",
						share: "",
						address: "",
						zip: "",
						town: "",
						nationality: ""
					}
				)
			else if(this.ud.company.hasOwners == false) 
				this.ud.owners = []

			this.$forceUpdate()
		},
		showEntities() {
			this.ud.company.hasEntities = this.ud.owner_question2.owner_structure_A1 ? true : false

			if(this.ud.company.hasEntities == true && this.ud.entitiesTypeOrg.length == 0) 
				this.ud.entitiesTypeOrg.push({
					name: "",
					org_no: "",
					address: "",
					zip: "",
					town: "",
					share: "",
					business_code: "",
					country: ""
				})
			else if(this.ud.company.hasEntities == false)
				this.ud.entitiesTypeOrg = []
			
			if(this.ud.company.hasEntities == true && this.ud.entitiesTypePerson.length == 0)
				this.ud.entitiesTypePerson.push({
					name: "",
					share: ""
				})
			else if(this.ud.company.hasEntities == false)
				this.ud.entitiesTypePerson = []

			this.$forceUpdate()
		},
		thousandsSeparator(num) {
			if (num.length > 3) {
				var num2 = num.toString().replace(/[\s]/g, '')
				return num2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
			} else return num
		},
		update(company) {
		  if(!company) return;
		  this.$root.searchOngoing = true
		},
		async searchNr(nr) {
			let link = "https://data.brreg.no/enhetsregisteret/api/enheter/" + nr
			
			try {
			  const [brreg] = await Promise.all([
				axios.get(link)
			  ])
			  
			  return brreg.data
			  
			} catch(e) {
			  return
			}
		},
		async doSearch(value) {
			let brregcompany = await this.searchNr(value)
			if(brregcompany) {
			  this.brregcompany = brregcompany
			  console.log(this.brregcompany)
			  this.update(brregcompany)
			  return this.brregcompany
			} 
			return null
		},
		async updateEntityOwnersOrg(org, index) {
			console.log(this.brreg_all_data)
			let orgbrreg = await this.doSearch(org)
			
			if(orgbrreg) {
				this.ud.entitiesTypeOrg[index].address = orgbrreg.forretningsadresse ? orgbrreg.forretningsadresse.adresse.toString() : ''
				this.ud.entitiesTypeOrg[index].zip = orgbrreg.forretningsadresse ? orgbrreg.forretningsadresse.postnummer : ''
				this.ud.entitiesTypeOrg[index].town = orgbrreg.forretningsadresse ? orgbrreg.forretningsadresse.poststed : ''		
				this.ud.entitiesTypeOrg[index].country = orgbrreg.forretningsadresse ? orgbrreg.forretningsadresse.land : ''	
				this.ud.entitiesTypeOrg[index].business = orgbrreg.naeringskode1 ? orgbrreg.naeringskode1.beskrivelse : ''
				this.ud.entitiesTypeOrg[index].business_code = orgbrreg.naeringskode1 ? orgbrreg.naeringskode1.kode : ''

				if(this.ud.entitiesTypeOrg[index].country)
					this.ud.entitiesTypeOrg[index].countryIndex = this.countries.indexOf(this.ud.entitiesTypeOrg[index].country)
			}
		},
		updateCompany (d) {
			this.ud.company.org_no = String(d.organisasjonsnummer) //orgno.substr(0,3) + " " + orgno.substr(3,3) + " " + orgno.substr(6,3)
			this.ud.company.name = d.navn
			this.ud.company.org_form = d.organisasjonsform.kode
			this.ud.company.registration_date = d.registreringsdatoEnhetsregisteret
			this.ud.company.employees = d.antallAnsatte
			this.ud.company.start_date = d.stiftelsesdato

			if( d.hjemmeside ) {
				this.ud.company.website = d.hjemmeside
			}

			if ( d.forretningsadresse ) {
				this.ud.company.address = d.forretningsadresse.adresse.toString()
				this.ud.company.zip = d.forretningsadresse.postnummer
				this.ud.company.town = d.forretningsadresse.poststed
				this.ud.company.country = d.forretningsadresse.land;
			}
			if ( d.naeringskode1 ) {
				this.ud.company.business = d.naeringskode1.beskrivelse
				this.ud.company.business_code = d.naeringskode1.kode
			}
			
			this.ud.company.inBrReg = true
			this.ud.company.inBusinessReg = d.registrertIForetaksregisteret// == "J"
			this.ud.company.inMvaReg = d.registrertIMvaregisteret// == "J"
			this.ud.company.bankrupt = d.konkurs// == "J"
			this.ud.company.liquidation = d.underAvvikling// == "J"
			this.ud.company.compulsoryLiquidation = d.underTvangsavviklingEllerTvangsopplosning// == "J"

			this.$forceUpdate()
		},
		async getAmlData(a) {

			const capitalize = (s) => {
				if (typeof s !== 'string') return ''
				var name = ""
				var strings = s.split(" ")

				for(var i in strings) {
					name += strings[i].charAt(0).toUpperCase() + strings[i].toLowerCase().slice(1)
					if(Number(i) !== (strings.length - 1))
						name += " "
				}
				return name
			}

			var dev = false
			console.log("aml init")
		  	if(this.role == 'owner' && !this.searchComplete && !dev){
				this.searching = true;
				
				console.log(this.searchComplete)
				var company = null
				this.signers = []

				if(Number(a)) {
					company =  await oidc.norway.getCompanyInfo(a, ["ownership.beneficial","ownership.shareholders","authorities","sanction","officialRoles"])
				} else {
					company = a
				}

				this.ud.amlOriginalSearch = company
				console.log(this.amlOriginalSearch)
				
				// Ceo contact person
				if(company.officialRoles.ceo) {
					this.ud.contacts[0].fname = capitalize(company.officialRoles.ceo.firstName)
					this.ud.contacts[0].lname = capitalize(company.officialRoles.ceo.lastName)
				} else if(company.officialRoles.proprietor) {
					this.ud.contacts[0].fname = capitalize(company.officialRoles.proprietor.firstName)
					this.ud.contacts[0].lname = capitalize(company.officialRoles.proprietor.lastName)
				}

				if(company.officialRoles) {
					for (var i in company.officialRoles){
						if(i !== 'companySecretary') {
							var obj = company.officialRoles[i]

							if(obj.length) {
								for(var j in obj) {
									if(obj[j].dateOfBirth) {
										var dob = obj[j].dateOfBirth.split("-")[2] + "." + obj[j].dateOfBirth.split("-")[1] + "." + obj[j].dateOfBirth.split("-")[0]
										this.ud.officialRoles.push({role: this.__(i), name: capitalize(obj[j].name), firstname: capitalize(obj[j].firstName), lastname: capitalize(obj[j].lastName), dob: dob})
									} else {
										this.ud.officialRoles.push({role: this.__(i), name: obj[j].name})
									}
								}
							} else if(obj.name) {
								if(obj.dateOfBirth) {
									var dob = obj.dateOfBirth.split("-")[2] + "." + obj.dateOfBirth.split("-")[1] + "." + obj.dateOfBirth.split("-")[0]
									this.ud.officialRoles.push({role: this.__(i), name: capitalize(obj.name), firstname: capitalize(obj.firstName), lastname: capitalize(obj.lastName), dob: dob})
								} else {
									this.ud.officialRoles.push({role: this.__(i), name: obj.name})
								}
							}
						}
					}  
				}

				// Sanction
				if(company.sanction){
					if(company.sanction.status === 'NONE'){
						this.ud.sanctionMsg = company.sanction.message
						this.ud.sanction = false
					}
					if(company.sanction.status != 'NONE'){
						this.ud.sanctionMsg = company.sanction.message
						this.ud.sanction = true
					}
				}

				// Relle rettighetshavere
				if(company.ownership.beneficial && company.ownership.beneficial.length != 0){
					for (let k = 0; k < company.ownership.beneficial.length; k++){
						var ownerNames = company.ownership.beneficial[k].name.split(" ");
						if (company.ownership.beneficial[k].dateOfBirth) {
							var dob = company.ownership.beneficial[k].dateOfBirth.split("-")[2] + "." + company.ownership.beneficial[k].dateOfBirth.split("-")[1] + "." + company.ownership.beneficial[k].dateOfBirth.split("-")[0]
							var owner = company.ownership.beneficial[k]
						
							var zip = ''
							if(owner.address && owner.address.postalCode && owner.address.postalCode.length >= 3)
								var zip = owner.address.postalCode.length === 4 ? owner.address.postalCode : '0' + owner.address.postalCode
						
							this.ud.owners.push({
								name: owner.name, 
								fname: ownerNames.slice(1).join(" "), 
								lname: ownerNames[0], 
								dob: owner.dateOfBirth,
								share: owner.percentageControl, 
								address: owner.address ? owner.address.street:'', 
								zip: owner.address ? zip :'', 
								town: owner.address ? owner.address.city:'' 
							});
						}
					}
				}
				if (this.ud.owners.length > 0){
					this.ud.company.hasOwners = true
					this.ud.owner_question1.owners_A1 = true
					this.ud.ownersFound = true
				}else{
					this.ud.ownersFound = false
				}


				/*if(company.ownership.beneficial && company.ownership.beneficial.length != 0){
					this.ud.company.hasOwners = true
					this.ud.owner_question1.owners_A1 = true
					this.ud.ownersFound = true
					for (let k = 0; k < company.ownership.beneficial.length; k++){
						var ownerNames = company.ownership.beneficial[k].name.split(" ");  
						var dob = company.ownership.beneficial[k].dateOfBirth.split("-")[2] + "." + company.ownership.beneficial[k].dateOfBirth.split("-")[1] + "." + company.ownership.beneficial[k].dateOfBirth.split("-")[0]
						var owner = company.ownership.beneficial[k]
						
						var zip = ''
						if(owner.address && owner.address.postalCode && owner.address.postalCode.length >= 3)
							var zip = owner.address.postalCode.length === 4 ? owner.address.postalCode : '0' + owner.address.postalCode
						
						this.ud.owners.push({
							name: owner.name, 
							fname: ownerNames.slice(1).join(" "), 
							lname: ownerNames[0], 
							dob: owner.dateOfBirth,
							share: owner.percentageControl, 
							address: owner.address ? owner.address.street:'', 
							zip: owner.address ? zip :'', 
							town: owner.address ? owner.address.city:'' 
						});
					} 
				} else this.ud.ownersFound = false*/

				//Eiere Juridisk
				if(company.ownership && company.ownership.shareholders && company.ownership.shareholders.length != 0){
					this.ud.company.hasEntities = true
					this.ud.owner_question2.owner_structure_A1 = true
					this.ud.entitesFound = true
					for (let l = 0; l < company.ownership.shareholders.length; l++){
						if(company.ownership.shareholders[l].type == 'PERSON') {
							this.ud.entitiesTypePerson.push({
								name: company.ownership.shareholders[l].name, 
								share: company.ownership.shareholders[l].percentage
							});
						} else {
							this.ud.entitiesTypeOrg.push({
								name: company.ownership.shareholders[l].name, 
								org_no: company.ownership.shareholders[l].organizationNumber, 
								share: company.ownership.shareholders[l].percentage,
								address: "",
								zip: "",
								town: "",
								business_code: "",
								country: ""
							});
							this.updateEntityOwnersOrg(company.ownership.shareholders[l].organizationNumber, this.ud.entitiesTypeOrg.length-1)
						}
					} 
				} else this.ud.entitesFound = false

				if(company.authorities) {
					if(company.authorities.signatureRights) {
						// Add signature rules
						this.ud.requirementsDescription = company.authorities.signatureRights.description
						
						// Add signers with signature rights
						if(company.authorities.signatureRights.options && company.authorities.signatureRights.options.length > 1) {
							for(var index in company.authorities.signatureRights.options) 
								if(company.authorities.signatureRights.options[index].required) {
									if(this.signers.length > 0)
										this.signers = this.signers.concat([...company.authorities.signatureRights.options[index].required])
									else
										this.signers = [...company.authorities.signatureRights.options[index].required]
								}
						} else if(company.authorities.signatureRights.options && company.authorities.signatureRights.options[0]) {
							if(company.authorities.signatureRights.options[0].required && company.authorities.signatureRights.options[0].required.length > 0) {
								this.signers = [...company.authorities.signatureRights.options[0].required]
							}
						}
					}
					
					// Add signers with procura
					if(company.authorities.powerOfProcuration) {
						// Add signers with procuration
						if(company.authorities.powerOfProcuration.options && company.authorities.powerOfProcuration.options.length > 1) {
							for(var index in company.authorities.powerOfProcuration.options)
								if(company.authorities.powerOfProcuration.options[index].required) {
									for(var p in company.authorities.powerOfProcuration.options[index].required)
										if(this.signers.filter((el) => { return el.name === company.authorities.powerOfProcuration.options[index].required[p].name }).length === 0) {
											this.signers = this.signers.concat([...company.authorities.powerOfProcuration.options[index].required])
										}
								}
						} else if(company.authorities.powerOfProcuration.options && company.authorities.powerOfProcuration.options[0]) {
							if(company.authorities.powerOfProcuration.options[0].required && company.authorities.powerOfProcuration.options[0].required.length > 0)
								for(var p in company.authorities.powerOfProcuration.options[0].required)
									if(this.signers.filter((el) => { return el.name === company.authorities.powerOfProcuration.options[0].required[p].name }).length === 0) {
										this.signers = this.signers.concat([...company.authorities.powerOfProcuration.options[0].required])
									}
						}
					}

					if(this.signers && this.signers.length > 0) {
						for (let i = 0; i < this.signers.length; i++){
							this.ud.signers.push({name: this.signers[i].name, role: this.signers[i].role, dateOfBirth: this.signers[i].dateOfBirth});
							var role = this.__(this.signers[i].role)

							var signerNames = this.signers[i].name.split(" ")
							var name = capitalize(signerNames.slice(1).join(" ").split(" (")[0] + " " + signerNames[0])
							var dob = this.signers[i].dateOfBirth.split("-")[2] + "." + this.signers[i].dateOfBirth.split("-")[1] + "." + this.signers[i].dateOfBirth.split("-")[0]
							var signer = name + " (" + role + ", " + dob + ")"
							this.ud.signers_list.push(signer)
						}  
						
						console.log(this.ud.signers_list)
					}
				}			

				if(this.ud.officialRoles) {
					for(var o in this.ud.officialRoles) {
						if(this.ud.officialRoles[o].role === this.__("ceo") || 
							this.ud.officialRoles[o].role === this.__("chairman") ||
							this.ud.officialRoles[o].role === this.__("proprietor") || 
							this.ud.officialRoles[o].role === this.__("boardMembers")) {
								var name = this.ud.officialRoles[o].firstname + " "  + this.ud.officialRoles[o].lastname
								var signer = name + " (" + this.ud.officialRoles[o].role + ", " + this.ud.officialRoles[o].dob + ")"

								if(this.ud.signers_list.filter((el) => { return el.split(" (")[0] === name }).length === 0) {
									this.ud.signers.push({name: name, role: this.ud.officialRoles[o].role, dateOfBirth: this.ud.officialRoles[o].dob});
									this.ud.signers_list.push(signer);
								}
							}
					}
				}

				if(this.ud.signers_list.length > 0)
					this.ud.signers_list.push(this.__("signerNotInList"))

				this.searching = false
				this.searchComplete = true
				this.ud.amlSearchSuccessful = true
				return

			}
			if(this.role !== 'owner'){
				this.e6 = 2;
			}
		},
		async reject() {
			try {

				const [reject] = await Promise.all([
				  	axios({
						url: "/api" + this.envelope.data.documents[0].uid + "/status/aborted",
						method: "POST",
						body: {},
						headers: {
							"Authorization": "JWT " + auth.token,
							"x-namespace": auth.namespace
						}
					})
				])
				// Set template data
				// await this.envelope.firstTemplate().setUserData(this.ud)
				if(reject)
					this.aborted = true
			}
			catch(e) {
				console.log(e)
				this.exceptions.push(new Error("An error occured during envelope creation"))
				this.busy = false
			}
		},
		async submit() {

			this.formOpacity = 0
			window.scrollTo(0,0)
			this.busy = true
			this.ud.lang = this.$root.locale

			if(this.role == 'owner') {
				this.ud.fileName = "Advokatfirmaet Ræder - Kunderegistrering Selskap - " + this.ud.company.name + " - " + this.ud.company.org_no + ".pdf"

				if(this.ud.signer.name && this.ud.signer.name !== this.__("signerNotInList")) {
					var nameSplit = this.ud.signer.name.split(" "); 
					var name = (nameSplit.slice(0).join(" ").split(" (")[0]).split(" ")
					var fname = ""
					var lname = name[name.length-1]

					for(var n = 0; n < name.length - 1; n++) {
						if(n !== name.length-1)
							fname += name[n]
							
						if(name.length > 2 && n !== name.length-2 && n !== name.length-1)
							fname += " "
					}

					this.ud.signer.fname = fname
					this.ud.signer.lname = lname
				}
				
				try {
					// Create envelopes etc
					const envelope = await envelopes.create('raeder-kyc-company')
					console.log(envelope)

					// Get and set bearer token
					const descriptor = await envelopes.getDescriptor('raeder-kyc-company')
					const bearerToken = await envelope.getBearerToken(descriptor.flow.id)
					auth.setToken(bearerToken)

					// Parse remote envelope data into the local object
					await envelope.reflect()

					await envelope.firstDocument().put({ name: this.ud.fileName });
					await envelope.put({ 
						metadata: {
							silentParties: this.silentParties
						} 
					});
					/*this.silentParties
					"silentParties": [
						{
							"givenName": "Silent",
							"familyName": "Party",
							"email": "heidi.fagernes@verified.eu"
						}
					]*/

					// Set template data
					await envelope.firstTemplate().setUserData(this.ud)

					// Add recipient
					/*await envelope.addRecipient({
						givenName: this.ud.secretary.fname,
						familyName: this.ud.secretary.lname,
						language: this.$root.locale,
						email: this.ud.secretary.email,
						order: 1,
						role: {
							action: "edit",
							label: "Fylle i som sekretær",
							name: "sekretaer"
						}
					})*/

					await envelope.addRecipient({
						givenName: this.ud.representative.fname,
						familyName: this.ud.representative.lname,
						language: this.$root.locale,
						email: this.ud.representative.email,
						order: 1,
						role: {
							action: "edit",
							label: "Fylle i som første representant fra kundeutvalget",
							name: "kundeutvalg"
						}
					})

					// Publish the envelope
					await envelope.publish()

					//Reload page
					var successInfo = this.ud.representative.fname + " " + this.ud.representative.lname + " (" + this.ud.representative.email + ")"
					$cookies.set("success", true);
					$cookies.set("envelope", successInfo);
					location.reload();

				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
				}
			} else if(this.role == 'kundeutvalg'){
				//this.ud.finalSubmit = true
				this.ud._isForwarded = true
				
				try {
					// Add signers/recipient
					if(this.ud.approved.representative) 
						await this.envelope.addRecipient({
							givenName: this.ud.representative.fname,
							familyName: this.ud.representative.lname + " 2",
							language: this.$root.locale,
							email: this.ud.representative.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som andre representant fra kundeutvalget",
								name: "kundeutvalg-2"
							}
						})
					else {
						this.ud.aborted = true
						this.ud.abortedBy = this.role
						
						await this.envelope.addRecipient({
							givenName: this.ud.owner.fname,
							familyName: this.ud.owner.lname,
							language: this.$root.locale,
							email: this.ud.owner.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som advokat",
								name: "advokat"
							}
						})
					}

					// Set template data
					await this.envelope.firstTemplate().setUserData(this.ud)

					this.busy = false
					this.submitted = true
				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
					this.submitted = false
				}
			} else if(this.role == 'kundeutvalg-2'){
				this.ud.finalSubmit = true
				this.ud._isForwarded = true
				
				this.formatDates()
				
				var today = new Date()
				var day = today.getDate() > 9 ? today.getDate() : '0' + today.getDate()
				var month = (today.getMonth()+1) > 9 ? (today.getMonth()+1) : '0' + (today.getMonth()+1)
				this.ud.date = day + '.' + month +'.'+ today.getFullYear()

				try {
					// Add signers/recipient
					if(this.ud.approved.representative2) 
						await this.envelope.addRecipient({
							givenName: this.ud.signer.fname,
							familyName: this.ud.signer.lname,
							language: this.ud.signer.lang,
							email: this.ud.signer.email,
							signingMethod: this.ud.signer.signing.method,
							order: 3,
							role: {
								action: "sign",
								label: "Signere",
								name: "signer"
							}
						})
					else {
						this.ud.aborted = true
						this.ud.abortedBy = this.role
						
						await this.envelope.addRecipient({
							givenName: this.ud.owner.fname,
							familyName: this.ud.owner.lname,
							language: this.$root.locale,
							email: this.ud.owner.email,
							order: 3,
							role: {
								action: "edit",
								label: "Fylle i som advokat",
								name: "advokat"
							}
						})
					}
					
					if(this.ud.signer.greeting)
						await this.envelope.put({ greeting: this.ud.signer.greeting });

					// Set template data
					await this.envelope.firstTemplate().setUserData(this.ud)

					this.busy = false
					this.submitted = true
				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
					this.submitted = false
				}
			}
			
			/*
				else if(this.role == 'sekretaer') {
				this.ud._isForwarded = true

				try {
					// Add recipient
					if(this.ud.approved.secretary) 
						await this.envelope.addRecipient({
							givenName: this.ud.representative.fname,
							familyName: this.ud.representative.lname,
							language: this.$root.locale,
							email: this.ud.representative.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som kundeutvalg",
								name: "kundeutvalg"
							}
						})
					else {
						this.ud.aborted = true
						this.ud.abortedBy = this.role

						await this.envelope.addRecipient({
							givenName: this.ud.owner.fname,
							familyName: this.ud.owner.lname,
							language: this.$root.locale,
							email: this.ud.owner.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som advokat",
								name: "advokat"
							}
						})
					}
					// Set template data
					await this.envelope.firstTemplate().setUserData(this.ud)
					
					this.busy = false
					this.submitted = true
				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
					this.submitted = false
				}
				}
			*/
		},
		formatDates() {
			/** Date formatting */
			if (this.ud.company.start_date) {
				var startDate = new Date(this.ud.company.start_date)
				if (!isNaN(startDate.getTime())) {
					this.ud.company.start_date = startDate.toISOString().slice(0, 10)
				}
			}

			if (this.ud.company.registration_date) {
				var regDate = new Date(this.ud.company.registration_date)
				if (!isNaN(regDate.getTime())) {
					this.ud.company.registration_date = regDate.toISOString().slice(0, 10)
				}
			}

			if (this.ud.owners != undefined && this.ud.owners.length > 0) {
				this.ud.owners.forEach(owner => {
					var odob = new Date(owner.dob)
					if (!isNaN(odob.getTime())) {
						owner.dob = odob.toISOString().slice(0, 10)
					}
				})
			}

			if (this.ud.peps != undefined && this.ud.peps.length > 0) {
				this.ud.peps.forEach(pep => {
					var pdob = new Date(pep.dob)
					if (!isNaN(pdob.getTime())) {
						pep.dob = pdob.toISOString().slice(0, 10)
					}
				})
			}
		},
		async updateFormData(data) {
			for(var d in data)
				if(d !== 'aml')
					this.ud.company[d] = data[d]
					
			await this.getAmlData(data.aml)
			
			this.loading = false
			this.formOpacity = 1
		},
		async loadLists() {
			if(this.risks.length > 0) this.risks = []
			if(this.invoiceSpecification.length > 0) this.invoiceSpecification = []
			if(this.network.length > 0) this.network = []
			if(this.ranges.length > 0) this.ranges = []
			if(this.lawyers.length > 0) this.lawyers = []
			if(this.discounts.length > 0) this.discounts = []
			if(this.positions.length > 0) this.positions = []
			if(this.employees.length > 0) this.employees = []

			if(this.countries.length > 0) this.countries = []
			if(this.nationalities.length > 0) this.nationalities = []
			
			this.risks = [this.__('low'), this.__('medium'), this.__('high')]
			this.invoiceSpecification = [this.__('invoiceSpecA1'), this.__('invoiceSpecA2')]

			for(var p in this.positionsList)
				if(this.positionsList[p][this.$root.locale])
					this.positions.push(this.positionsList[p][this.$root.locale])

			for(var e in this.employeesList)
				if(this.employeesList[e]["name"])
					this.employees.push(this.employeesList[e]["name"])

			for(var n in this.networkList)
				if(this.networkList[n][this.$root.locale])
					this.network.push(this.networkList[n][this.$root.locale])

			for(var r in this.rangesList)
				if(this.rangesList[r]["value"])
					this.ranges.push(this.rangesList[r]["value"])
				
			for(var l in this.lawyersList)
				if(this.lawyersList[l]["name"])
					this.lawyers.push(this.lawyersList[l]["name"])
				
			for(var d in this.discountsList)
				if(this.discountsList[d]["value"])
					this.discounts.push(this.discountsList[d]["value"])

			for(var c in this.countriesList)
				this.countries.push(this.countriesList[c][this.$root.locale])
			this.countries.sort()

			for(var n in this.nationalitiesList)
				if(this.nationalities.indexOf(this.nationalitiesList[n][this.$root.locale]) === -1)
					this.nationalities.push(this.nationalitiesList[n][this.$root.locale]);
			this.nationalities.sort()

			if(this.role === 'owner') {
				if(this.ud.kyc_list.length > 0) this.ud.kyc_list = []
				if(this.ud.secretaries_list.length > 0) this.ud.secretaries_list = []
				if(this.ud.customer_selection_list.length > 0) this.ud.customer_selection_list = []
				
				if(this.silentParties.length > 0) this.silentParties = []

				if(this.signing_methods_list.length === 0) {
					try {
						const descriptor = await envelopes.getDescriptor('raeder-kyc-company')	
						var actions = descriptor.roles[0].action.methods

						for(var a in actions) {
							this.signing_methods_list.push(this.__(actions[a]))
							this.signingMethods[actions[a]] = { label: this.__(actions[a]) }
							
							switch(actions[a]) {
								case "bankid-no": this.signingMethods[actions[a]].lang = "nb_NO"; break;
								case "bankid-se": this.signingMethods[actions[a]].lang = "sv_SE"; break;
								case "nets-esign-dk": this.signingMethods[actions[a]].lang = "da_DK"; break;
								default: this.signingMethods[actions[a]].lang = "nb_NO"; break;
							}
						}
						console.log("finished")
					} catch(e) {
						console.log(e)
						this.exceptions.push(new Error("An error occured during signing methods loading"))
						this.busy = false
					}
				}

				/*this.ud.secretaries = this.secretariesList;
				for(var s in this.secretariesList) {
					this.ud.secretaries[s].name = this.secretariesList[s]["firstname"] + " " + this.secretariesList[s]["lastname"]
					this.ud.secretaries_list.push(this.ud.secretaries[s].name)
				}

				if(this.ud.secretaries_list.length > 0) this.ud.secretaries_list.push(this.__('recipientNotInList'))*/

				this.ud.kyc_list.push(this.translations.kyc_low[this.lang])
				this.ud.kyc_list.push(this.translations.kyc_medium[this.lang])
				this.ud.kyc_list.push(this.translations.kyc_high[this.lang])

				this.ud.customerSelection = this.customerSelectionList
				for(var c in this.customerSelectionList) {
					this.ud.customerSelection[c].name = this.customerSelectionList[c]["firstname"] + " " + this.customerSelectionList[c]["lastname"]
					this.ud.customer_selection_list.push(this.ud.customerSelection[c].name)
				}

				//IF only one representative in the list, the representative is automatically filled out
				if(this.ud.customer_selection_list.length == 1) {
					var index = Object.keys(this.ud.customerSelection)[0]
					this.ud.representative = {
						fname: this.ud.customerSelection[index]["firstname"],
						lname: this.ud.customerSelection[index]["lastname"],
						email: this.ud.customerSelection[index]["email"]
					}
				} else if(this.ud.customer_selection_list.length > 0) this.ud.customer_selection_list.push(this.__('recipientNotInList'))

				for(var s in this.silentPartiesList)
					if(this.silentPartiesList[s]["email"])
						this.silentParties.push({
							givenName: this.silentPartiesList[s]["firstname"],
							familyName: this.silentPartiesList[s]["lastname"],
							email: this.silentPartiesList[s]["email"]
						})

				this.steps_owner = [
					{ title: this.__("step_2"), button: this.__("step_2_button") },
					{ title: this.__("step_3"), button: this.__("step_3_button") },
					{ title: this.__("step_4"), button: this.__("step_4_button") },
					{ title: this.__("step_5"), button: this.__("step_5_button") },
					{ title: this.__("step_6"), button: this.__("step_6_button") },
					{ title: this.__("step_7"), button: this.__("step_7_button") },
					{ title: this.__("step_8"), button: this.__("step_8_button") }
				]
			} else {
				this.steps_secretary = [
					{ title: this.__("step_1"), button: this.__("step_1_button") },
					{ title: this.__("step_2"), button: this.__("step_2_button") },
					{ title: this.__("step_3"), button: this.__("step_3_button") },
					{ title: this.__("step_4"), button: this.__("step_4_button") },
					{ title: this.__("step_5"), button: this.__("step_5_button") },
					{ title: this.__("step_6"), button: this.__("step_6_button") },
					{ title: this.__("step_7"), button: this.__("step_7_button") },
					{ title: this.__("step_9"), button: this.__("step_9_button") }
				]

				this.steps_representative = [
					{ title: this.__("step_1"), button: this.__("step_1_button") },
					{ title: this.__("step_2"), button: this.__("step_2_button") },
					{ title: this.__("step_3"), button: this.__("step_3_button") },
					{ title: this.__("step_4"), button: this.__("step_4_button") },
					{ title: this.__("step_5"), button: this.__("step_5_button") },
					{ title: this.__("step_6"), button: this.__("step_6_button") },
					{ title: this.__("step_7"), button: this.__("step_7_button") },
					{ title: this.__("step_10"), button: this.__("step_10_button") }
				]
			}
		},
		initStepper() {
			if(document.getElementById('stepper')) {
				$(document).scroll(function() {
					var scrollDistance = $(document).scrollTop();
					var stickyMenu = $("#stepper");
					if(scrollDistance >= 88)  {
						stickyMenu.css({
							 position: "fixed",
							 "top" : "0",
							 "left" : "0" 
						});
					} else {
						stickyMenu.css("position", "relative");
					}
				});
			}
		},
		idClick(id) { 
			document.getElementById(id).click(); 
		},
		handleFileUpload(event, model) {
			console.log("File button clicked..")
			this[model][0] = event.target.files[0]
			
			console.log("File added: " + this[model][0])
			this.$forceUpdate()
		}
	},
	async created() {
		Vue.use(VueCookies)

		const [positions, employees, network, secretaries, customerSelection, ranges, lawyers, discounts, silentParties, countries, nationalities] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/positions'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/employees'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/network'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/secretaries'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/customerSelection'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/ranges'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/lawyers'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/discounts'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/silentParties'),
			axios.get('https://sheets.web-services.verified.eu/countries/countries'),
			axios.get('https://sheets.web-services.verified.eu/countries/nationalities')
		])

		this.positionsList = positions.data
		this.employeesList = employees.data
		this.networkList = network.data
		this.secretariesList = secretaries.data
		this.customerSelectionList = customerSelection.data
		this.rangesList = ranges.data
		this.lawyersList = lawyers.data
		this.discountsList = discounts.data
		this.silentPartiesList = silentParties.data
		this.countriesList = countries.data
		this.nationalitiesList = nationalities.data
		this.zipJson = zipNO

		this.redirectURL = this.__config('redirect') || "https://www.raeder.no/"

		if(window.innerWidth <= 800){ 
			this.smallWindow = true;
		}

		if(this.userdata) {
			this.ud = { ...this.ud, ...this.userdata }
			console.log(this.ud)

			console.log("Rolle kyc_company: " + this.role)
		}

		this.isIE = false || !!document.documentMode
		this.isEdge = navigator.appVersion.indexOf('Edge') > -1

		console.log("isIE: " + this.isIE)
		console.log("isEdge: " + this.isEdge)

		this.loadLists()

		if(this.role === 'owner') {
			this.setInBrreg()
			
			if(this.__('default_greeting')) this.ud.signer.greeting = this.__('default_greeting')

			if(this.$attrs.searchResult) {
				this.alreadyCompletedSearch = true
				await this.updateFormData(this.$attrs.searchResult)
			} else {
				this.formOpacity = 1
			}

			this.ud.formType = "customer"
			this.setFormType()

		} else {
			this.formOpacity = 1
			this.$forceUpdate()
		}
		this.loading = false

		this.$nextTick(function () {
			this.initStepper()
		});
	}
}