import axios from 'axios'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Portal from '../components/portal/portal'
import KYC_Company from '../components/form-kyc-company/form-kyc-company'
import KYC_Person from '../components/form-kyc-person/form-kyc-person'
import Textarea2 from '../components/textarea2/textarea2'
import Checkbox2 from '../components/checkbox2/checkbox2'
import Radio2 from '../components/radio2/radio2'
import { auth, envelopes } from '@verified/libvf'

export default {
	name: 'app',
	data() {
		return {
			qp: [],
			sheetname: "raeder-portal",
			translations: {},
			configuration: {},
			alreadyFilled: false,
			envelope: undefined,
			lang: "nb_NO",
			loading: true,
			exceptions: [],
			attachments: {},
			attachments_checked: {},
			hidePortalContent: true,
			contentOpacity: 1,
			formOpacity: 1,
			step: 0,
			steps: [],
			searchResult: null,
			searchResultOpacity: 0,
			temp: {},
			role: undefined,
			company: undefined,
			countries: [],
			customerType: [],
			searching: false,
			searchCompleted: false,
			smallWindow: false,
			signers: {
				client: null,
				clientPosition: null,
				clientEmail: null,
				clients: [],
				clientsDd: [],
				self: null,
				selfPosition: null,
				selfEmail: null,
				selfs: [],
				selfsDd: []
			},
			userdata: {},
			ud: {
				form: undefined
			}
		}
	},
	watch: {
		'signers.client': function(newVal) {
			let i = this.signers.clientsDd.indexOf(this.signers.client)
			if(newVal && i !== -1 && this.signers.clients[i].BusinessPosition)
				this.signers.clientPosition = this.signers.clients[i].BusinessPosition.Description
		},
		'signers.self': function(newVal) {
			let i = this.signers.selfsDd.indexOf(this.signers.self)
			if(newVal && i !== -1) {
				this.signers.selfPosition = this.signers.selfs[i].position
				this.signers.selfEmail = this.signers.selfs[i].email
			}
		},
		windowWidth: function () {
			if(window.innerWidth <= 800){ 
				this.smallWindow = true;
			}
		}
	},
	methods: {
		// Return translation for given key
		__(key) {
			if(this.translations[key])
				return this.translations[key][this.$root.locale]
		},
		// Return config label for given key and language, or value if no label
		__config(key) {
			if(this.configuration[key])
				if(this.configuration[key][this.$root.locale])
					return this.configuration[key][this.$root.locale]
				else
					return this.configuration[key].value
		},
		colorLuminance(hex, lum) {
			// Validate hex string
			hex = String(hex).replace(/[^0-9a-f]/gi, '')
			if(hex.length < 6)
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			lum = lum || 0;
			
			// Convert to decimal and change luminosity
			let rgb = '#', c, i
			for(i = 0; i < 3; i++) {
				c = parseInt(hex.substr(i * 2, 2), 16)
				c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
				rgb += ('00' + c).substr(c.length)
			}
			return rgb
		},
		async init(el, envelope, data) {
			this.user = el

			if(this.user === "editor") {
				try {
					this.envelope = envelope ? envelope : await envelopes.getFromUrl()
					var data = data ? data : await this.envelope.firstTemplate().getUserData()
					this.ud.form = data.form
					console.log("Form: " + this.ud.form)
					this.ud = {...this.ud, ...data}
					
					if(this.envelope.data.documents[0].files && this.envelope.data.documents[0].files.attachments)
						if(this.envelope.data.documents[0].files.attachments.length > 0) {
							this.ud.showPDF = true
							this.ud.pdfFile = this.envelope.data.documents[0].files.attachments[0]
							console.log("File found: " + this.ud.pdfFile.name)
						}

					this.userdata = this.ud
					this.role = auth.role
					console.log("Rolle: " + this.role)

					if((this.role=='kundeutvalg'&&this.ud.approved.representative)||(this.role=='kundeutvalg-2'&&this.ud.approved.representative2)) {
						this.alreadyFilled = true;
						this.loading = false;
					} else this.fadeToStep(1)
					/*if (this.ud.finalSubmit) {
						if (localStorage.getItem(this.envelope.data.id)) {
							this.signUrl = localStorage.getItem(this.envelope.data.id);
							console.log(this.signUrl);
							window.location.href = this.signUrl;
							this.loading = false;
							this.$forceUpdate();
						} else {
							this.alreadyFilled = true;
							this.loading = false;
						}
					} else {
						
					}*/
				}
				catch (e) {
					console.log(e)
					$cookies.remove("user")
					$cookies.remove("token")
					this.loading = false
				}
			} else {
				this.ud.owner = {
					fname: this.user.givenName,
					lname: this.user.familyName,
					email: this.user.email
				}
				this.role = this.user.role
				this.company = this.user.company
				this.hidePortalContent = false
			}
		},
		async formSelected(value) {
			//Form or URL (can't have space)
			if(value && value.indexOf(" ") !== -1) {
				this.hidePortalContent = true
				this.ud.form = value.split(" ")[1]

				if(this.role === "owner")
					this.ud.translations = this.ud.form=='kyc_company' ? this.translationsCompany : this.translationsPerson
				
				this.userdata = this.ud
				/*
				setTimeout(() => {
					this.initDefaultAttachments()
					this.$forceUpdate()
				}, 1000)
				*/
				
				if(this.ud.form === 'search')
					this.fadeToStep(20)
				else if(this.ud.form === 'select')
					this.fadeToStep(0)
				else
					this.fadeToStep(1)
			} else if(value) {
				try {
					let token = $cookies.get("token")
					let user = $cookies.get("user")
					let link = value// + "?access_token=" + token + "&c=" + user.namespace.split('/').pop()
					window.open(link)
				} catch(e) {
					console.error(e)
					this.exceptions.push(new Error('An error occured during get bearer token'))
				}
			}
		},
		fadeToStep(step) {
			this.formOpacity = 0
			setTimeout(() => {
				this.step = step
				if(step === 0)
					this.hidePortalContent = false
				setTimeout(() => {
					this.contentOpacity = this.formOpacity = 1
				}, 10)
			}, 500)
		},
		nextStep() {
			// if (this.ud.professional && this.step == 4) {
			// 	if(!this.validateForm(`form5`)) {
			// 		return
			// 	}
			// } else if(!this.validateForm(`form${this.step}`)) {
			// 	return
			// }
			
			if(this.step < 5)
				this.fadeToStep(this.step + 1)
			else
				this.submit()
		},
		companySelected(company) {
			if(company) {
				this.ud.company = company
				
				// Init signers
				if(company.roles && company.roles.length) {
					this.signers.clients = company.roles.filter((el) => {
						return el.BusinessPosition && (el.BusinessPosition.BusinessPositionCode === 'DL' || el.BusinessPosition.BusinessPositionCode === 'SF')
					})
					
					this.signers.clients.forEach((el) => {
						if(el.BusinessPosition && el.Person && el.Person.Name && el.Person.Name.LastName && el.Person.Name.FirstName) {
							let name = el.Person.Name.LastName.toLowerCase() + ', ' + el.Person.Name.FirstName.toLowerCase() + ' (' + el.BusinessPosition.Description + ')'
							
							//Capitalize
							this.signers.clientsDd.push(name.replace(/(?:^|\s)\S/g, (a) => { return a.toUpperCase() }))
						}
					})
				}
			}
		},
		initDefaultAttachments() {
			Object.values(this.attachments).forEach((atc, i) => {
				this.attachments_checked[i] = atc.default_for_form.includes(this.ud.form)
			})
			
			let atcs = document.getElementsByClassName('attachments')
			if(atcs.length)
				try {
					for(let i = 0; i < atcs.length; i++)
						for(let j = 0, nodes = atcs[i].childNodes[0].childNodes; j < nodes.length; j++)
							if(this.attachments_checked[j])
								nodes[j].classList.add('default')
							else
								nodes[j].classList.remove('default')
				} catch(e) {}
		},
		setLanguage (value){
			this.lang = value
			this.$root.locale = this.lang
			this.setIso(this.$root.locale)
			this.$forceUpdate()
		},
		setSearch (val) {
			this.searching = val
			this.$forceUpdate()
		},
		setSearchData (obj) {
			if(obj) {
				this.searchResult = obj
				setTimeout(() => {
					this.searchCompleted = true
					this.$forceUpdate()
				}, 500)
			}
		}
	},
	async created() {
		Vue.use(VueCookies)
		Vue.use(Portal)
		Vue.use(KYC_Company)
		Vue.use(KYC_Person)

		Vue.use(Textarea2)
		Vue.use(Checkbox2)
		Vue.use(Radio2)
		
		this.$root.locale = 'nb_NO'
		this.setIso("nb_NO")
		
		//Get sheets data
		try {
			if(this.sheetname) {
				this.loadingLists = true
				
				// Get sheet data
				const [translations, pdfCompany, pdfPerson, portal, config, brreg] = await Promise.all([
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/translations'),
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/pdf-translations-company'),
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/pdf-translations-person'),
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/portal-translations'),
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/config'),
					axios.get('https://sheets.web-services.verified.eu/brreg-translations/brreg-translations')
				])
				
				this.translations = { ...brreg.data, ...portal.data, ...translations.data }
				this.configuration = config.data

				this.translationsCompany = { ...pdfCompany.data, ...brreg.data } 
				this.translationsPerson = pdfPerson.data
				
				this.mergeLocale(this.translations)

				this.mergeLocale({
					"validation.allCheckboxRequired": {
						en_EN: "All checkboxes must be ticked",
						nb_NO: "Alle avmerkingsboksene må være avkrysset."
					}
				})

				//Set page favicon and title
				if(this.__config('favicon'))
					document.getElementById('favicon').href = this.__config('favicon')
				if(this.__config('title'))
					document.title = this.__config('title')
				
				//Set CSS colors
				if(this.__config('primary_color')) {
					document.documentElement.style.setProperty('--primary', this.__config('primary_color'))
					document.documentElement.style.setProperty('--highlight', this.colorLuminance(this.__config('primary_color'), -0.18))
					document.documentElement.style.setProperty('--tooltip', this.__config('primary_color'))
					document.documentElement.style.setProperty('--secondary', this.__config('secondary_color'))
					document.documentElement.style.setProperty('--navbar', this.__config('navbar_background'))
					document.documentElement.style.setProperty('--navbarColor', this.__config('navbar_color'))
				}
			}
		} catch(e) {
			console.error(e)
			this.exceptions.push(new Error('Unable to get sheet data. Try refreshing your browser.'))
		}
		
		if(window.innerWidth <= 800){ 
			this.smallWindow = true;
		}
		
		this.loading = false
	}
}