<template>
<div>

    <div class="list-group custom-control custom-radio" >
        <button type="button" 
            :class="['list-group-item', 'list-group-item-action', {'checked': value[id] }]" 
            v-for="(item, id) in options" 
            :key="id"
            @click="handleListClick('ve-radio-' + _uid + '-' + id)">
                <table class="list-table">
                    <tr>
                        <th>{{ item.label }}</th>
                        <th>
                            <input type="radio"
                                class="custom-control-input"
                                :value="value"
                                :checked="value[id]"
                                @change="handleInput"
                                :id="'ve-radio-' + _uid + '-' + id"
                                :disabled="disabled">
                            <label class="custom-control-label custom-radio-label" style="margin-left: 20px;"
                                :for="'ve-radio-' + _uid + '-' + id"></label>
                        </th>
                    </tr>
                </table>
        </button>
    </div>
    
    <div v-show="validation_error" class="custom-invalid-feedback">
        <span>{{ __("validation.radio")}}</span>
    </div>

</div>
</template>

<script>

export default {
    name: 've-radio-v2',
    
    props: {
        value: {
            type: Object,
            default: () => { return {} }
        },
        options: {
            type: Object,
            default: {}
        },
        rules: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    
    data() {
        return {
            validation_error: false
        }
    },
    
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.performValidation(val)
            }
        }
    },
    
    methods: {
        
        handleInput(e) {
            
            let id = e.srcElement.id.split('ve-radio-' + this._uid + '-')[1]
			
			for(let key in this.value)
				this.value[key] = key === id;
			
			this.$emit('input', this.value)
			this.$emit('radio-click', true)
			
			this.performValidation()
			this.$forceUpdate()
            
        },

        handleListClick(e) {
            let id = e.split('ve-radio-' + this._uid + '-')[1]
			
			for(let key in this.value)
				this.value[key] = key === id;
			
			this.$emit('input', this.value)
			this.$emit('radio-click', true)
			
			this.performValidation()
			this.$forceUpdate()
        },

        performValidation() {
            if(this.rules.includes('required')) {
				
				let ok = false
				
				for(let key in this.value)
					if(this.value[key]) ok = true
				
				for(let key in this.value) {
					if(!ok) {
						document.getElementById(`ve-radio-${ this._uid }-${ key }`).setCustomValidity('Select one of the radiobuttons')
						this.validation_error = true
					}
					else {
						document.getElementById(`ve-radio-${ this._uid }-${ key }`).setCustomValidity('')
						this.validation_error = false
					}
				}
			}
        }
    },
    
    created() {
        
        for(let option in this.options)
            if(!this.value[option])
                this.value[option] = false
        this.$emit('input', this.value)
        
        this.$nextTick(() => {
            this.performValidation()
        })
    }
}
</script>

<style lang="scss"
       scoped>
    .list-group .checked {
        color: #444444;
        background-color: #5334ff36;
    }
    .list-group-item-action {
        outline: none;
    }
    
</style>
