<template>
<div>
    
    <div v-for="(item, id) in options"
         :key="id"
         :class="['custom-control', { 'custom-control-inline': inline }, 'custom-checkbox', 'mb-1']">
        
        <input type="checkbox"
               class="custom-control-input"
               :value="value"
               :checked="value[id]"
               @change="handleInput"
               :id="'ve-cb-' + _uid + '-' + id"
               :disabled="disabled">
        <label class="custom-control-label custom-checkbox-label"
               :for="'ve-cb-' + _uid + '-' + id">{{ item.label }}</label>
    </div>
    
    <div v-show="validation_error" class="custom-invalid-feedback">
        <span v-if="rules.includes('requiredAll')">{{ __("validation.allCheckboxRequired")}}</span>
        <span v-else>{{ __("validation.checkbox")}}</span>
    </div>

</div>
</template>

<script>

export default {
    name: 've-checkbox-v2',
    
    props: {
        value: {
            type: Object,
            default: () => { return {} }
        },
        options: {
            type: Object,
            default: {}
        },
        rules: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    
    data() {
        return {
            validation_error: false
        }
    },
    
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.performValidation(val)
            }
        }
    },
    
    methods: {
        
        handleInput(e) {
            
            let id = e.srcElement.id.split('ve-cb-' + this._uid + '-')[1]
            
            this.value[id] = e.srcElement.checked ? true : false
            
            this.$emit('input', this.value)
            
            this.performValidation()
            
            this.$forceUpdate()
            
        },
        
        performValidation() {
            if(this.rules.includes('required')) {
                
                let ok = false
                
                for(let key in this.value)
                    if(this.value[key]) ok = true
                
                for(let key in this.value) {
                    if(!ok) {
                        document.getElementById(`ve-cb-${ this._uid }-${ key }`).setCustomValidity('Select at least one of the checkboxes')
                        this.validation_error = true
                    }
                    else {
                        document.getElementById(`ve-cb-${ this._uid }-${ key }`).setCustomValidity('')
                        this.validation_error = false
                    }
                }
            }

            if(this.rules.includes('requiredAll')) {
                
                let ok = false
                var total = 0
                var count = 0
                
                for(let key in this.value) {
                    if(this.value[key]) count++
                    total++
                }
                
                if(count === total) ok = true
                
                for(let key in this.value) {
                    if(!ok) {
                        document.getElementById(`ve-cb-${ this._uid }-${ key }`).setCustomValidity('All checkboxes must be ticked.')
                        this.validation_error = true
                    }
                    else {
                        document.getElementById(`ve-cb-${ this._uid }-${ key }`).setCustomValidity('')
                        this.validation_error = false
                    }
                }
            }
        }
    },
    
    created() {
        
        for(let option in this.options)
            if(!this.value[option])
                this.value[option] = false
        this.$emit('input', this.value)
        
        this.$nextTick(() => {
            this.performValidation()
        })
    }
}
</script>

<style lang="scss"
       scoped>

</style>
