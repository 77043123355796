import axios from 'axios'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Datepicker from 'vuejs-datepicker'
import {nbNO, en} from 'vuejs-datepicker/dist/locale'
import { auth, envelopes, oidc } from '@verified/libvf'
import zipNO from '../../assets/postnummer_no.json'

export default {
	name: "form-kyc-person",
	props: {
		sheetname: {
			type: String
		},
		role: {
			type: String
		},
		account: {
			type: String
		},
		lang: {
			type: String
		},
		userdata: {
			type: Object,
			default: function() { return {} }
		},
		envelope: {
			type: Object,
			default: function() { return {} }
		},
		config: {
			type: Object,
			default: function() { return {} }
		},
		translations: {
			type: Object
		},
		mobile: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			en: en,
			no: nbNO,
			zipJson: undefined,
			loading: true,
			submitted: false,
			searching: false,
			logout: "../logout.png",
			loader: "../loader64.gif",
			new_doc: "../new-document.png",
			logo: undefined,
			qp: [],
			company: {},
			loggedIn: false,
			error: false,
			failedAuth: undefined,
			redirectURL: undefined,
			loadingLists: false,
			busy: false,
			exceptions: [],
			failed: false,
			auth: {},
			step: 1,
			steps_owner: [{ title: "", button: "" }],
			steps_secretary: [{ title: "", button: "" }],
			steps_representative: [{ title: "", button: "" }],
			invoiceSpecification: [],
			network: [],
			ranges: [],
			risks: [],
			employees: [],
			reasons: [],
			silentParties: [],
			signingMethods : {},
			signing_methods_list: [],
			recipients: [],
			searchQuery: undefined,
			ud: {
				owner: {},
				person: {
					reputation: {
						risk: "",
						otherDesc: ""
					}
				},
				company: {
					name: "",
					org_no: "",
					address: "",
					zip: "",
					town: "",
					registration_date: "",
					employees: "",
					start_date: "",
					org_form: "",
					business_code: "",
					business: ""
				},
				reason: {
					val: ""
				},
				secretary: {
					fname: "",
					lname: "",
					email: ""
				},
				representative: {
					fname: "",
					lname: "",
					email: ""
				},
				signer: {
					fname: "",
					lname: "",
					email: "",
					signing: {}
				},
				secretaries_list: [],
				customer_selection_list: [],
				rejectedComment: "",
				wasReferred: undefined,
				riskIndex: undefined,
				referredByIndex: undefined,
				invoiceSpecificationIndex: undefined,
				networkIndex: undefined,
				annualTurnoverIndex: undefined,
				approved: {
					representative: undefined,
					representative2: undefined
				},
				companyRequired: {
					company_true: false,
					company_false: false
				}
			},
			approved: undefined,
			smallWindow: false,
			windowWidth: 0,
			formOpacity: 0,
			isIE: undefined,
			isEdge: undefined,
			aborted: undefined
		}
	},
	components: {
		Datepicker
	},
	watch: {
		windowWidth: function () {
			if(window.innerWidth <= 800){ 
				this.smallWindow = true;
			}
		},
		lang: function (val) {
			var v = val
			console.log(v)
			this.loadLists()
		}
	},
	methods: {
		// Return config for given key
		__config(key) {
			if (this.config[key])
				return this.config[key].value
			return null
		},
		getURL() {
			if (location.search) {
				if (location.search.substring(1, 2) == "&") {
					this.qp = location.search.substring(0, 1) + location.search.substring(2, location.search.length);
					this.qp = JSON.parse("{\"" + decodeURI(qp.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
				} else
					this.qp = JSON.parse("{\"" + decodeURI(location.search.substr(1)).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}");
			}
		},
		close() {
			location.href = this.redirectURL
		},
		validateForm(form) {
			return this.$refs[form].validate()
		},
		validateDatepicker(id) {
			var element = document.getElementById(id);
			if(element) {
				var value = element.value

				if(!value) {
					this.showInvalidFeedback[id] = true;
					return false
				} else {
					this.showInvalidFeedback[id] = false;
					return true
				}
			}
		},
		fadeToStep(step) {
			this.formOpacity = 0
			setTimeout(() => {
				this.step = step
				if(step === 0)
					this.hidePortalContent = false
				setTimeout(() => {
					this.contentOpacity = this.formOpacity = 1
				}, 10)
			}, 500)
		},
		async nextStep() {
			if(this.role == 'owner') {
				if(this.step > 0 && this.step <= 3)
					if(!this.validateForm(`form${this.step+1}`))
						return

				if(this.step == 1) {
					this.ud.person.name = this.ud.person.fname + ' ' + this.ud.person.lname
					this.ud.signer.name = this.ud.person.name
					this.ud.signer.fname = this.ud.person.fname
					this.ud.signer.lname = this.ud.person.lname
					this.ud.signer.email = this.ud.person.email
				}

				if(this.step < 3)
					this.fadeToStep(this.step + 1)
				else 
					return this.submit()

			}
			
			/**if(this.role == 'sekretaer'||this.role == 'kundeutvalg') {
				if(this.step > 0 && this.step <= 4)
					if(!this.validateForm(`form${this.step}`))
						return
			}*/

			if(this.role == 'kundeutvalg'||this.role == 'kundeutvalg-2') {
				if (this.step == 1) {
					this.fadeToStep(2)
				} else if(this.step > 1 && this.step <= 4)
					if(!this.validateForm(`form${this.step}`))
						return

				if(this.step < 4)
					this.fadeToStep(this.step + 1)
				else 
					return this.submit()
			}
		},
		previousStep() {
			this.fadeToStep(this.step - 1)
		},
		setCompanyRequired() {
			this.ud.company.required = this.ud.companyRequired.company_true ? true : false
			this.$forceUpdate()
		},
		setReferred() {
			this.ud.wasReferred = this.ud.referred.referred_true ? true : false
			this.$forceUpdate()
		},
		/*setReason() {
			this.ud.reason.other = this.ud.reason.val == this.reasons[this.reasons.length-1] ? true : false
			if(this.ud.reason.val) this.ud.reason.selectedIndex = this.reasons.indexOf(this.ud.reason.val)
			this.$forceUpdate()
		},*/
		setRisk() {
			this.ud.person.reputation.other = this.ud.person.reputation.risk !== this.__('low') ? true : false
			if(this.ud.person.reputation.risk) this.ud.person.reputation.selectedIndex = this.risks.indexOf(this.ud.person.reputation.risk)
			this.$forceUpdate()
		},
		setApproved() {
			/*if(this.role == 'sekretaer')
				this.ud.approved.secretary = this.approved.approved_true ? true : false

			if(this.role == 'kundeutvalg')
				this.ud.approved.representative = this.approved.approved_true ? true : false
			*/
			if(this.role == 'kundeutvalg')
				this.ud.approved.representative = this.approved.approved_true ? true : false

			if(this.role == 'kundeutvalg-2')
				this.ud.approved.representative2 = this.approved.approved_true ? true : false

			this.$forceUpdate()
		},
		setSecretary() {
			if(this.ud.secretary.name) {
				for(var s in this.ud.secretaries) {
					if(this.ud.secretaries[s].name === this.ud.secretary.name)  {
						this.ud.secretary.fname = this.ud.secretaries[s]["firstname"]
						this.ud.secretary.lname = this.ud.secretaries[s]["lastname"]
						this.ud.secretary.email = this.ud.secretaries[s]["email"]
						break
					}
				}
				
				this.$forceUpdate()
			}
		},
		setCustomerSelection() {
			if(this.ud.representative.name) {
				for(var c in this.ud.customerSelection) {
					if(this.ud.customerSelection[c].name === this.ud.representative.name) {
						this.ud.representative.fname = this.ud.customerSelection[c]["firstname"]
						this.ud.representative.lname = this.ud.customerSelection[c]["lastname"]
						this.ud.representative.email = this.ud.customerSelection[c]["email"]
						break
					}
				}
				
				this.$forceUpdate()
			}
		},
		setSigningMethods() {
			if(this.ud.signer.signing.label) {
				for(var method in this.signingMethods)
					if(this.signingMethods[method].label === this.ud.signer.signing.label) {
						this.ud.signer.signing.method = method
						this.ud.signer.lang = this.signingMethods[method].lang
					}
			}

			this.$forceUpdate()
		},
		addSigner(fname, lname, email, signingMethod, order) {
			this.recipients.push({
				givenName: fname,
				familyName: lname,
				email: email,
				order: order,
				language: this.$root.locale,
				signingMethod: signingMethod,
				role: {
					action: "sign",
					label: "Fylle i skjemaet",
					name: "signer"
				}
			})
		},
		updateCompany (d) {
			this.ud.company.org_no = String(d.organisasjonsnummer) //orgno.substr(0,3) + " " + orgno.substr(3,3) + " " + orgno.substr(6,3)
			this.ud.company.name = d.navn
			this.ud.company.org_form = d.organisasjonsform.kode
			this.ud.company.registration_date = d.registreringsdatoEnhetsregisteret
			this.ud.company.employees = d.antallAnsatte
			this.ud.company.start_date = d.stiftelsesdato

			if ( d.forretningsadresse ) {
				this.ud.company.address = d.forretningsadresse.adresse.toString()
				this.ud.company.zip = d.forretningsadresse.postnummer
				this.ud.company.town = d.forretningsadresse.poststed
				this.ud.company.country = d.forretningsadresse.land;
			}
			if ( d.naeringskode1 ) {
				this.ud.company.business = d.naeringskode1.beskrivelse
				this.ud.company.business_code = d.naeringskode1.kode
			}
			
			this.ud.company.inBrReg = true
			this.ud.company.inBusinessReg = d.registrertIForetaksregisteret// == "J"
			this.ud.company.inMvaReg = d.registrertIMvaregisteret// == "J"
			this.ud.company.bankrupt = d.konkurs// == "J"
			this.ud.company.liquidation = d.underAvvikling// == "J"
			this.ud.company.compulsoryLiquidation = d.underTvangsavviklingEllerTvangsopplosning// == "J"

			this.$forceUpdate()
		},
		async reject() {
			try {

				const [reject] = await Promise.all([
				  	axios({
						url: "/api" + this.envelope.data.documents[0].uid + "/status/aborted",
						method: "POST",
						body: {},
						headers: {
							"Authorization": "JWT " + auth.token,
							"x-namespace": auth.namespace
						}
					})
				])
				// Set template data
				// await this.envelope.firstTemplate().setUserData(this.ud)
				if(reject)
					this.aborted = true
			}
			catch(e) {
				console.log(e)
				this.exceptions.push(new Error("An error occured during envelope creation"))
				this.busy = false
			}
		},
		async submit() {

			this.formOpacity = 0
			this.busy = true
			this.ud.lang = this.$root.locale

			if(this.role == 'owner') {
				this.ud.fileName = "Advokatfirmaet Ræder - Kunderegistrering Privatperson - " + this.ud.person.name + ".pdf"

				try {
					// Create envelopes etc
					const envelope = await envelopes.create('raeder-kyc-person')
					console.log(envelope)

					// Get and set bearer token
					const descriptor = await envelopes.getDescriptor('raeder-kyc-person')
					const bearerToken = await envelope.getBearerToken(descriptor.flow.id)
					auth.setToken(bearerToken)

					// Parse remote envelope data into the local object
					await envelope.reflect()

					await envelope.firstDocument().put({ name: this.ud.fileName });
					await envelope.put({ 
						metadata: {
							silentParties: this.silentParties
						} 
					});
					/*this.silentParties
					"silentParties": [
						{
							"givenName": "Silent",
							"familyName": "Party",
							"email": "heidi.fagernes@verified.eu"
						}
					]*/	
					// Set template data
					await envelope.firstTemplate().setUserData(this.ud)

					// Add recipient
					/*await envelope.addRecipient({
						givenName: this.ud.secretary.fname,
						familyName: this.ud.secretary.lname,
						language: this.$root.locale,
						email: this.ud.secretary.email,
						order: 1,
						role: {
							action: "edit",
							label: "Fylle i som sekretær",
							name: "sekretaer"
						}
					})*/

					await envelope.addRecipient({
						givenName: this.ud.representative.fname,
						familyName: this.ud.representative.lname,
						language: this.$root.locale,
						email: this.ud.representative.email,
						order: 1,
						role: {
							action: "edit",
							label: "Fylle i som første representant fra kundeutvalget",
							name: "kundeutvalg"
						}
					})

					// Publish the envelope
					await envelope.publish()

					//Reload page
					var successInfo = this.ud.representative.fname + " " + this.ud.representative.lname + " (" + this.ud.representative.email + ")"
					$cookies.set("success", true);
					$cookies.set("envelope", successInfo);
					location.reload();

				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
				}
			} else if(this.role == 'kundeutvalg'){
				//this.ud.finalSubmit = true
				this.ud._isForwarded = true
				
				try {
					// Add signers/recipient
					if(this.ud.approved.representative) 
						await this.envelope.addRecipient({
							givenName: this.ud.representative.fname,
							familyName: this.ud.representative.lname + " 2",
							language: this.$root.locale,
							email: this.ud.representative.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som andre representant fra kundeutvalget",
								name: "kundeutvalg-2"
							}
						})
					else {
						this.ud.aborted = true
						this.ud.abortedBy = this.role
						
						await this.envelope.addRecipient({
							givenName: this.ud.owner.fname,
							familyName: this.ud.owner.lname,
							language: this.$root.locale,
							email: this.ud.owner.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som advokat",
								name: "advokat"
							}
						})
					}

					// Set template data
					await this.envelope.firstTemplate().setUserData(this.ud)

					this.busy = false
					this.submitted = true
				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
					this.submitted = false
				}
			} else if(this.role == 'kundeutvalg-2') {
				this.ud.finalSubmit = true
				this.ud._isForwarded = true

				try {
					// Add signers/recipient
					if(this.ud.approved.representative2) 
						await this.envelope.addRecipient({
							givenName: this.ud.signer.fname,
							familyName: this.ud.signer.lname,
							language: this.$root.locale,
							email: this.ud.signer.email,
							signingMethod: this.ud.signer.signing.method,
							order: 3,
							role: {
								action: "sign",
								label: "Signere",
								name: "signer"
							}
						})
					else {
						this.ud.aborted = true
						this.ud.abortedBy = this.role
						
						await this.envelope.addRecipient({
							givenName: this.ud.owner.fname,
							familyName: this.ud.owner.lname,
							language: this.$root.locale,
							email: this.ud.owner.email,
							order: 3,
							role: {
								action: "edit",
								label: "Fylle i som advokat",
								name: "advokat"
							}
						})
					}
					
					if(this.ud.signer.greeting)
						await this.envelope.put({ greeting: this.ud.signer.greeting });

					// Set template data
					await this.envelope.firstTemplate().setUserData(this.ud)

					this.busy = false
					this.submitted = true
				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
					this.submitted = false
				}
			}

			/**
			 else if(this.role == 'sekretaer') {
				this.ud._isForwarded = true

				try {
					// Add recipient
					if(this.ud.approved.secretary) 
						await this.envelope.addRecipient({
							givenName: this.ud.representative.fname,
							familyName: this.ud.representative.lname,
							language: this.$root.locale,
							email: this.ud.representative.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som kundeutvalg",
								name: "kundeutvalg"
							}
						})
					else {
						this.ud.aborted = true
						this.ud.abortedBy = this.role

						await this.envelope.addRecipient({
							givenName: this.ud.owner.fname,
							familyName: this.ud.owner.lname,
							language: this.$root.locale,
							email: this.ud.owner.email,
							order: 2,
							role: {
								action: "edit",
								label: "Fylle i som advokat",
								name: "advokat"
							}
						})
					}
					// Set template data
					await this.envelope.firstTemplate().setUserData(this.ud)
					
					this.busy = false
					this.submitted = true
				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope creation"))
					this.busy = false
					this.submitted = false
				}
			} 
			 */
		},
		async loadLists() {
			if(this.risks.length > 0) this.risks = []
			if(this.invoiceSpecification.length > 0) this.invoiceSpecification = []
			if(this.network.length > 0) this.network = []
			if(this.ranges.length > 0) this.ranges = []
			if(this.employees.length > 0) this.employees = []
			if(this.reasons.length > 0) this.reasons = []

			this.risks = [this.__('low'), this.__('medium'), this.__('high')]
			this.invoiceSpecification = [this.__('invoiceSpecA1'), this.__('invoiceSpecA2')]
			
			for(var n in this.networkList)
				this.network.push(this.networkList[n][this.$root.locale])

			for(var r in this.rangesList)
				if(this.rangesList[r]["value"])
					this.ranges.push(this.rangesList[r]["value"])
				
			for(var e in this.employeesList)
				this.employees.push(this.employeesList[e]["name"])

			for(var r in this.reasonsList)
				this.reasons.push(this.reasonsList[r][this.$root.locale])

			if(this.role === 'owner') {
				if(this.ud.secretaries_list.length > 0) this.ud.secretaries_list = []
				if(this.ud.customer_selection_list.length > 0) this.ud.customer_selection_list = []
				
				if(this.silentParties.length > 0) this.silentParties = []

				this.ud.secretaries = this.secretariesList;
				for(var s in this.secretariesList) {
					this.ud.secretaries[s].name = this.secretariesList[s]["firstname"] + " " + this.secretariesList[s]["lastname"]
					this.ud.secretaries_list.push(this.ud.secretaries[s].name)
				}

				if(this.ud.secretaries_list.length > 0) this.ud.secretaries_list.push(this.__('recipientNotInList'))

				this.ud.customerSelection = this.customerSelectionList;
				for(var c in this.customerSelectionList) {
					this.ud.customerSelection[c].name = this.customerSelectionList[c]["firstname"] + " " + this.customerSelectionList[c]["lastname"]
					this.ud.customer_selection_list.push(this.ud.customerSelection[c].name)
				}

				//IF only one representative in the list, the representative is automatically filled out
				if(this.ud.customer_selection_list.length == 1) {
					var index = Object.keys(this.ud.customerSelection)[0]
					this.ud.representative = {
						fname: this.ud.customerSelection[index]["firstname"],
						lname: this.ud.customerSelection[index]["lastname"],
						email: this.ud.customerSelection[index]["email"]
					}
				} else if(this.ud.customer_selection_list.length > 0) this.ud.customer_selection_list.push(this.__('recipientNotInList'))

				for(var s in this.silentPartiesList)
					if(this.silentPartiesList[s]["email"])
						this.silentParties.push({
							givenName: this.silentPartiesList[s]["firstname"],
							familyName: this.silentPartiesList[s]["lastname"],
							email: this.silentPartiesList[s]["email"]
						})
					
				this.steps_owner = [
					{ title: this.__("step_2"), button: this.__("step_2_button") },
					{ title: this.__("step_3"), button: this.__("step_3_button") },
					{ title: this.__("step_8"), button: this.__("step_8_button") }
				]

				if(this.signing_methods_list.length === 0) {
					try {
						const descriptor = await envelopes.getDescriptor('raeder-kyc-person')	
						var actions = descriptor.roles[0].action.methods

						for(var a in actions) {
							this.signing_methods_list.push(this.__(actions[a]))
							this.signingMethods[actions[a]] = { label: this.__(actions[a]) }
							
							switch(actions[a]) {
								case "bankid-no": this.signingMethods[actions[a]].lang = "nb_NO"; break;
								case "bankid-se": this.signingMethods[actions[a]].lang = "sv_SE"; break;
								case "nets-esign-dk": this.signingMethods[actions[a]].lang = "da_DK"; break;
								default: this.signingMethods[actions[a]].lang = "nb_NO"; break;
							}
						}
						console.log("finished")
					} catch(e) {
						console.log(e)
						this.exceptions.push(new Error("An error occured during signing methods loading"))
						this.busy = false
					}
				}
			} else {
				this.steps_secretary = [
					{ title: this.__("step_1"), button: this.__("step_1_button") },
					{ title: this.__("step_2"), button: this.__("step_2_button") },
					{ title: this.__("step_3"), button: this.__("step_3_button") },
					{ title: this.__("step_9"), button: this.__("step_9_button") }
				]

				this.steps_representative = [
					{ title: this.__("step_1"), button: this.__("step_1_button") },
					{ title: this.__("step_2"), button: this.__("step_2_button") },
					{ title: this.__("step_3"), button: this.__("step_3_button") },
					{ title: this.__("step_10"), button: this.__("step_10_button") }
				]
			}
		},
		initStepper() {
			if(document.getElementById('stepper')) {
				$(document).scroll(function() {
					var scrollDistance = $(document).scrollTop();
					var stickyMenu = $("#stepper");
					if(scrollDistance >= 88)  {
						stickyMenu.css({
							 position: "fixed",
							 "top" : "0",
							 "left" : "0" 
						});
					} else {
						stickyMenu.css("position", "relative");
					}
				});
			}
		}
	},
	async created() {
		Vue.use(VueCookies)

		const [reasons, secretaries, customerSelection, network, ranges, employees, silentParties] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/reasons'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/secretaries'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/customerSelection'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/network'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/ranges'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/employees'),
			axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/silentParties')
		])

		this.reasonsList = reasons.data
		this.secretariesList = secretaries.data
		this.customerSelectionList = customerSelection.data
		this.networkList = network.data
		this.rangesList = ranges.data
		this.employeesList = employees.data
		this.silentPartiesList = silentParties.data
		this.zipJson = zipNO

		this.redirectURL = this.__config('redirect') || "https://www.raeder.no/"

		if(window.innerWidth <= 800){ 
			this.smallWindow = true;
		}

		if(this.userdata) {
			this.ud = { ...this.ud, ...this.userdata }
			console.log(this.ud)
		}

		this.isIE = false || !!document.documentMode
		this.isEdge = navigator.appVersion.indexOf('Edge') > -1

		console.log("isIE: " + this.isIE)
		console.log("isEdge: " + this.isEdge)

		this.loadLists()

		/*if(this.role === 'kundeutvalg-2') {
			if(this.__('default_greeting')) this.ud.signer.greeting = this.__('default_greeting')
		}*/

		this.loading = false
		this.formOpacity = 1

		this.$nextTick(function () {
			this.initStepper()
		});
	}
}