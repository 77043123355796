<template>
    <div>
        <textarea ref="field" @input="handleInput" :value="value" v-mask="mask" :placeholder="placeholder" :rows="rows" :maxlength="maxlength" :readonly="readonly" class="form-control" :blurvalidate="blurvalidate"></textarea>
        <div v-if="!validation.status" class="textarea-invalid-feedback">{{ __(validation.key, validation.args) }}</div>
        <div v-if="maxlength" class="textarea-maxlength">{{ value.length }} / {{ maxlength }}</div>
    </div>
</template>

<script>
import { validate } from "../../../node_modules/@verified/veform/src/validations"

export default {
    name: "ve-textarea2",
    props: {
        value: [String, Number],
        maxlength : [String, Number],
        rows : {
            type: String,
            default: "2"
        },
        rules: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        mask: {
            type: null,
            default: ""
        },
        blurvalidate: {
            type: null,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            validation: { status: true }
        }
    },
    methods: {
        handleInput(e) {

            this.performValidation(e.srcElement.value)

            this.$emit('input', e.srcElement.value)

        },

        performValidation(value) {
            
            this.validation = validate(value, this.rules)

            if(this.validation.status) {
                this.$refs.field.setCustomValidity("") 
            }
            else {
                this.$refs.field.setCustomValidity(this.validation.message)
            }
                
        },

        validateFieldOnBlur() {

            var field = this.$refs.field;

            field.addEventListener("blur", onBlur, { passive: true });

			function onBlur() {
				field.parentNode.classList.add('was-validated');
				field.removeEventListener("blur", onBlur);
			}

        }

    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.performValidation(val)
            }
        }
    },
    mounted() {

        this.performValidation(this.value)

        if (this.blurvalidate !== null) {
            this.validateFieldOnBlur()
        }

    }
}
</script>

<style>
.textarea-maxlength {
    margin-top: 0.25rem;
    float: right;
    font-size: 80%;
}

.textarea-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #b62537;
    display: none;
}

.was-validated .textarea-invalid-feedback {
    display: inline;
}
</style>
